@import "../../themes/generated/variables.base.scss";
@import "../../themes/var.custom.scss";

.call-center {
    // overflow: auto;
    height: 100%;

    .key-queue {
        margin: 0 6px 6px 6px;
        padding: 10px;
        border-radius: 5px;
        background-color: $danger;
        color: white;
        cursor: pointer;

        span {
            display: inline-block;
            margin-right: 10px;
        }
    }

    .cc-customer {
        padding: 0 5px 5px 5px;
    }
    .cc-order-row {
        padding: 0 5px 5px 5px;
    }

    .dx-accordion-item {
        border-radius: $big-radius;
        
        .dx-accordion-item-title {
            background-color: $header-bg;
            padding: 6px 10px;
        }        

        &.dx-state-hover > .dx-accordion-item-title {
            background-color: $header-bg;
        }
    }

    .dx-accordion-item-body {
        padding: 5px;  
    }

    .dx-datagrid-rowsview.dx-empty {
        height: 30px;
    }

    .dx-datagrid .dx-row > td {
        padding-top: 4px;
        padding-bottom: 4px;
    }  

    .dx-datagrid-headers {
        border-top: 1px solid $base-border-color;
    }

    .dx-layout-manager .dx-field-item {
        padding-bottom: 4px;

        &:not(.dx-first-row) {
            padding-top: 4px;
        }
    }

    .dx-editor-cell .dx-texteditor .dx-texteditor-input {
        height: auto;
        line-height: normal;
        border: 1px solid rgba(0,0,0,0.42);
        border-radius: 2px;
    }

    .dx-field-item.dx-label-v-align .dx-invalid-message > .dx-overlay-wrapper {
        transform: translate(0, -2px)!important;
    }
}

@media (min-width: 960px) {
    .call-center {
        position: relative;
        overflow: hidden;

        .cc-col-left {
            margin-right: 450px;
            height: 100%;
        }

        .cc-col-main {
            width: 450px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}

@media (min-width: 1280px) {
    .call-center {
        .cc-col-left {
            margin-right: 800px;
            height: 100%;
        }

        .cc-col-main {
            width: 800px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .cc-order {
            width: 450px;
            height: 100%;
        }

        .cc-col-sidebar {
            width: 350px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}
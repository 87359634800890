@import "../../themes/generated/variables.base.scss";
@import "../../themes/var.custom.scss";

.stocktaking-container {
    h4 {
        margin: 0;
        font-size: 16px;
    }

    .stocktaking-note {
        margin: 0 0 10px 10px;
        color: $warning;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-top: 2px solid $base-accent;
        margin-bottom: 20px;
    }

    th, td {
        border: 1px solid $base-border-color;
        padding: 2px;
    }

    th {
        color: $head-text-color;
    }

    .stocktaking-button {
        text-align: right;
        margin-bottom: 40px;
    }

    fieldset {
        border-radius: 4px;
        margin-bottom: 20px;
    }

    legend {
        font-size: 16px;
    }
}
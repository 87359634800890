.popup-content-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.popup-content-main {
    flex: 1 1 auto;
    overflow: hidden;
}
.popup-content-footer {
    flex: 0 0 40px;
    padding-top: 15px;
    margin-bottom: -10px;
    margin-right: -5px;
    text-align: right;
    .dx-button+.dx-button {
        margin-left: 5px;
    }
}
.side-nav-outer-toolbar {
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
}

.layout-header {
    z-index: 1501;
}

.layout-body {
    flex: 1;
    min-height: 0;
}

.content {
    flex-grow: 1;
}

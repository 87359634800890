@import "../../../themes/generated/variables.base.scss";
@import "../../../themes/var.custom.scss";

.area-delivering {
    background-color: #fff;

    table {
        width: 100%;
        border-collapse: collapse;
        border-top: 2px solid $base-accent;
    }

    th, td {
        border: 1px solid $base-border-color;
        padding: 4px;
    }

    th {
        color: $head-text-color;
    }
}
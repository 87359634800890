@import "../../themes/generated/variables.base.scss";

.image-uploader {
    min-width: 200px;
    min-height: 200px;

    .dropzone {
        text-align: center;
        width: 100%;
        flex-grow: 1;
        background-color: rgba($color: $base-accent, $alpha: 0.1);

        img {
            width: 100%;
            height: auto;
        }
    }

    .dropzone-active {
        background-color: rgba($color: $base-accent, $alpha: 0.3);
    }
}

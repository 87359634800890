@import "../../themes/generated/variables.base.scss";
@import "../../themes/var.custom.scss";

.app .content .home-welcome {
    padding: 50px;
    text-align: center;
    
    h1 {
        font-size: 60px;
    }
    h2 {
        font-size: 40px;
    }
}

.home-content {
    padding: 10px 20px 20px 20px;

    .custom-panel .custom-panel-title {
        background-color: #fff;
        border-bottom: 1px solid $base-border-color;
    }

    svg {
        margin: 0 auto;
    }
}

.home-row {
    padding-top: 10px;
}

.home-flex-row {
    display: flex;
    flex-wrap: wrap;

    > div {
        flex: 1 0 auto;
    }
}

.home-count-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-right: -10px;

    > div {
        flex: 1 0 auto;
        margin-right: 10px;
    }
}

.home-count {
    padding: 15px;
    display: flex;
    align-items: center;
}

.home-count-icon {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    text-align: center;
    border-radius: 50%;
    padding-top: 10px;

    i {
        font-size: 40px;
        color: #fff;
    }
}

.home-count-content {
    flex: 1 0 auto;
    padding-left: 15px;
}

.home-count-caption {
    font-size: 18px;
}

.home-count-value {
    font-size: 20px;
    font-weight: bold;
}

.home-count-order {
    background-color: $base-accent;
}
.home-count-customer {
    background: #666;
}
.home-count-check {
    background-color: $warning;
}
.home-count-danger {
    background-color: $danger;
}
.home-count-dangerfix {
    background-color: $success;
}

.screen-x-small {
    .home-content {
        padding: 5px;

        .full-panel {
            padding: 5px;
        }
    }
}
.toolbar-position-filter {
    .dx-textbox {
        display: inline-block;

        &+.dx-textbox {
            margin-left: 10px;
        }
    }
}

@media (max-width: 400px) {
    .toolbar-position-filter {
        .dx-textbox {
            display: block;
            &+.dx-textbox {
                margin-left: 0;
            }
        }
    }
}
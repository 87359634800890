@import "../../themes/generated/variables.base.scss";
@import "../../themes/var.custom.scss";

.multi-image-uploader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .image-block {
        width: 100px;
        height: 100px;
        margin: 5px;
        flex: none;
        position: relative;
        border: 1px solid $base-border-color;
        border-radius: $base-border-radius;

        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: $base-border-radius;
        }

        .image-block-close {
            display: block;
            position: absolute;
            right: 5px;
            top: 5px;
        }

        .bi {
            font-size: 20px;
            color: $danger;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}
@import "../../themes/generated/variables.base.scss";

.login-card {
    position: relative;
    height: 100%;
    overflow: hidden;
    background:-webkit-linear-gradient(right,#346f8a,#99c991);
	background:-o-linear-gradient(right,#346f8a,#99c991);
	background:-moz-linear-gradient(right,#346f8a,#99c991);
	background:linear-gradient(to right,#346f8a,#99c991);
    // z-index: -99;

    .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content > .dx-scrollview-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .dx-card {
        width: 330px;
        margin: auto auto;
        padding: 30px;
        flex-grow: 0;

        .screen-x-small & {
            width: 90%;
            // height: 460px;
            border-radius: 5px;
            box-shadow: none;
            margin: auto auto;
            border: 0;
        }

        .header {
            margin-bottom: 10px;
            text-align: center;
            img {
                width: 50px; 
            }
            .title {
                color: $base-text-color;
                line-height: 28px;
                font-weight: 400;
                font-size: 20px;
            }

            .description {
                color: rgba($base-text-color, alpha($base-text-color) * 0.7);
                line-height: 18px;
            }
        }
    }
}

@import "../../themes/generated/variables.base.scss";
@import "../../themes/var.custom.scss";

.login-form {
    .login-about {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        color: $disabled;
    }

    .form-text {
        margin: 10px 0;
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }

    .dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
        padding-top: 10px;
    }
}

@import "../../themes/generated/variables.additional.scss";
@import "../../themes/var.custom.scss";

.side-navigation-menu {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    width: 220px !important;

    .menu-container {
        min-height: 100%;
        display: flex;
        flex: 1;
        padding-bottom: 60px;

        .dx-treeview {
            // ## Long text positioning
            white-space: nowrap;
            color: $header-bg;
            // ##

            // ## Icon width customization
            .dx-treeview-item {
                padding: 5px 0;
                padding-left: 0;
                padding-right: 0;
                min-height: auto;

                .dx-icon {
                    width: 44px !important;
                    margin: 0 !important;
                    font-size: 16px;
                }
            }
            // ##
            
            // ## Arrow customization
            .dx-treeview-node {
                padding: 0 0 !important;
            }

            .dx-treeview-toggle-item-visibility {
                right: 10px;
                left: auto;
                height: 38px;
            }

            .dx-rtl .dx-treeview-toggle-item-visibility {
                left: 10px;
                right: auto;
            }
            // ##

            // ## Item levels customization
            .dx-treeview-node {
                &[aria-level='1'] {
                    //font-weight: bold;
                    // border-bottom: 1px solid $base-border-color;
                }
        
                &[aria-level='2'] .dx-treeview-item-content {
                    font-weight: normal;
                    padding: 0 44px;
                }
            }
            // ##
        }

        // ## Selected & Focuced items customization
        .dx-treeview {
            .dx-treeview-node-container {
                .dx-treeview-node {
                    &.dx-state-selected:not(.dx-state-focused)> .dx-treeview-item {
                        background: transparent;
                    }

                    &.dx-state-selected > .dx-treeview-item * {
                        color: $base-accent;
                    }

                    &:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover {
                        background-color: lighten($base-bg, 4.00);
                    }
                }
            }
        }

        .dx-theme-generic .dx-treeview {
            .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
                color: inherit;
            }
        }
        // ##
    }


    // .dx-swatch-additional .dx-treeview-item {
    //     min-height: auto;
    // }
}

.dx-drawer-overlap.pre-init-blink-fix {
    @import "../../hooks/patches.scss";
    @include menu-pre-init-patch;
}

.tree-context-menu {
    .dx-menu-item .dx-menu-item-content {
        padding: 6px 20px;
    }
}
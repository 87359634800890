@import "../../themes/generated/variables.base.scss";

.reset-password-form {
    .submit-button {
        margin-top: 10px;
    }

    .login-link {
        color: $base-accent;
        font-size: 16px;
        text-align: center;
    }
}

@import "../../themes/generated/variables.base.scss";

.footer {
    position: fixed;
    z-index: 2;
    bottom: 0px;
    width: 100%;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    padding: 15px;
    background-color: $base-bg;
}

/* 此样式针对表格在小高度中的显示，隐藏以正常使用表格（如iPhone横屏） */
@media (max-height: 400px) {
    .footer {
        display: none;
    }
}
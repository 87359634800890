@import "../../themes/var.custom.scss";

.message-box {
    margin-right: 10px;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;

    i {
        font-size: 16px;
        margin-top: 5px;
        display: inline-block;
    }

    .message-num {
        position: absolute;
        background-color: $danger;
        color: #fff;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        font-size: 10px;
        text-align: center;
        top: -5px;
        right: -5px;
    }
}

.screen-x-small {
    .message-box {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        
        i {
            margin-top: 2px;
            font-size: 12px;
        }

        .message-num {
            right: -8px;
        }
    }
}
@import "./themes/generated/variables.base.scss";
@import "./themes/var.custom.scss";

.app {
    background-color: #eef5f9;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.app .content {
    line-height: 1.5;
    padding: 0;
    position: relative;
    overflow-y: auto;
    h2 {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.screen-x-small {
    .with-footer > .dx-scrollable-wrapper >
    .dx-scrollable-container > .dx-scrollable-content {
        height: 100%;

        & > .dx-scrollview-content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
    height: 100%;

    & > .dx-scrollview-content {
        display: flex;
        flex-direction: column;
        // height: 90%;
    }
}

// -------------分隔线，以上是布局，以下为样式重写-------------------

html, body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

.dx-card {
    border-radius: $big-radius !important;
}

.dx-button {
    border-radius: $base-border-radius !important;
}

.dx-button.dx-state-disabled {
    opacity: 1;
}

.dx-toolbar {
    .dx-toolbar-items-container {
        height: 40px;
    }
}

.dx-toolbar-menu-custom {
    .dx-button, .dx-selectbox {
        width: 100%;
        margin: 3px 0;
    }  
}


.dx-texteditor.dx-editor-outlined {
    border-radius: $base-border-radius !important;
}

.dx-toast-content {
    padding: 15px 30px;
}

.checkbox-all {
    padding: 10px;
    border-bottom: 1px solid $base-accent;
}

.checkbox-list {
    padding: 10px;
    
    > div {
        margin-bottom: 10px;
    }
}

.checkbox-list-3 {
    display: flex;
    flex-wrap: wrap;

    > div {
        flex: 0 0 33.333333%;
    }
}

.dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
    padding-top: 15px;
}

.dx-theme-material-typography h2 {
    font-weight: 400;
    padding-left: 5px;
}

.dx-datagrid-headers {
    border-top: 2px solid $base-accent;
}

.dx-treelist-headers {
    border-top: 2px solid $base-accent;
}

.dx-popup-normal {
    border-radius: $big-radius !important;

    .dx-popup-title.dx-toolbar {
        background-color: #eee;
    }

    .dx-popup-bottom {
        padding-top: 6px;
        padding-bottom: 6px;
        .dx-button {
            height: auto;
        }
        .dx-button-content {
            font-size: 16px;
            padding: 8px 20px;
        }
    }
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 44px;
}

.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, 
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, 
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, 
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
    background-color: rgba($base-accent, 0.3);
}

.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none, 
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none, 
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none, 
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none, 
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
    border-left: none;
    border-right: none;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, 
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, 
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, 
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
    background-color: rgba($base-accent, 0.3);
}

.dx-datagrid-group-panel .dx-group-panel-message {
    padding: 12px 0;
}

.dx-datagrid-group-panel .dx-group-panel-item {
    padding: 4px 8px;
}

.dx-data-row.dx-row-lines td {
    border-left: 1px dotted #e0e0e0;
}

.dx-pager {
    border-top: 1px solid $base-border-color;
    padding: 6px 10px;
}

.dx-command-select {
    padding: 0!important;
}

.dx-list-group-body {
    padding-left: 15px;
}

.dx-field-item-label-text {
    color: #666;
    font-weight: bold;
    font-size: 14px;
}
.dx-field-item-optional-mark, .dx-field-item-required-mark {
    color: $danger;
}

.dx-rtl .dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input, 
.dx-rtl .dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input, 
.dx-rtl.dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input, 
.dx-rtl.dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input, 
.dx-texteditor.dx-editor-filled .dx-placeholder::before, 
.dx-texteditor.dx-editor-filled .dx-texteditor-input, 
.dx-texteditor.dx-editor-outlined .dx-placeholder::before, 
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
    padding: 8px;
}

// .dx-invalid-message.dx-overlay-wrapper {
//     text-shadow: #000 0px 0px 1px;
// }
.dx-invalid-message > .dx-overlay-content {
    padding: 0;
}

.dx-layout-manager .dx-field-item {
    padding: 5px;
}
.dx-layout-manager .dx-field-item:not(.dx-first-row) {
    padding-top: 5px;
}

.dx-tagbox.dx-editor-filled .dx-tag-container, 
.dx-tagbox.dx-editor-outlined .dx-tag-container {
    min-height: auto;
    padding: 0;
}
.dx-tagbox .dx-tag {
    margin: 2px 2px;
}

.dx-scrollbar-vertical{
    &.dx-scrollbar-hoverable {
        width: 13px;
    }
    .dx-scrollable-scroll {
        width: 13px;
    }
}

.dx-scrollbar-horizontal {
    &.dx-scrollbar-hoverable {
        height: 13px;
    }
    .dx-scrollable-scroll {
        height: 13px;
    }
}

.dx-pivotgrid-container {
    border-top: 2px solid $base-accent;
}
.dx-pivotgrid .dx-pivotgrid-area-data tbody td {
    background-color: #ffffff;
}
.dx-pivotgrid .dx-pivotgrid-area-data .dx-row-total, .dx-pivotgrid .dx-pivotgrid-vertical-headers .dx-row-total {
    background-color: rgba(221,221,221,.2);
}

// ------分隔线，以上为重写，以下为自定义-------- 
.global-dialog {
    font-size: 16px;
    min-width: 360px;
    max-width: 98%;
    color: $base-text-color;

    .icon-body {
        display: flex;
        align-items: center;
        word-break: break-word;
        i {
            font-size: 28px;
            margin-right: 10px;
            color: #999;
        }
    }
    .dx-texteditor-input {
        border: 1px solid $base-accent;
        border-radius: 2px;
        padding: 4px;
        font-size: 16px;
        margin-top: 10px;
        &.invalid {
            border-color: $danger;
        }
    }
}

@media (max-width: 600px) {
    .global-dialog {
        max-height: 400px;
        min-width: auto;
        overflow: auto;
    }
}

.dx-icon-gas-bottle {
    background-image: url(assets/bottle.svg);
    background-repeat: no-repeat;
    background-position: center center!important;
    background-size: 22px 22px!important;
}

.text-primary { 
    color: $base-accent!important; 
}
.text-success {
    color: $success!important;
}
.text-warning {
    color: $warning!important;
}
.text-danger {
    color: $danger!important;
}
.text-disabled {
    color: $disabled!important;
}

.no-margin {
    margin: 0;
}

.sm-margin {
    margin: 10px 0;
}

.text-margin {
    margin-right: 10px;
}

.dx-button.dx-button-custom {
    box-shadow: 0px 0px 2px rgba(#000, 0.4) !important;
}

.page-toolbar {
    padding: 0 5px;

    .dx-toolbar {
        background-color: transparent;
    }

    .dx-button {
        box-shadow: 0px 0px 2px rgba(#000, 0.4) !important;
    }
    .dx-button-has-text .dx-button-content {
        padding: 5px 8px;
    }
    
    .dx-textbox {
        background-color: rgba($base-bg, 0.7);
    }
}
// .screen-x-small .page-large-toolbar .dx-toolbar .dx-toolbar-items-container {
//     height: 76px;
// }

.grid-container {
    border-top: 1px solid $base-border-color;
    border-bottom: 1px solid $base-border-color;
}

.test-row {
    background-image: url(assets/test-mode.png);
    background-repeat: repeat-x;
    background-position: center center;
}

.buttonbar {
    text-align: right;
    padding-top: 10px;

    .dx-button+.dx-button {
        margin-left: 5px;
    }
}

.link-button {
    color: $base-accent!important;
    cursor: pointer;
    text-decoration: none;
    
    &:hover {
        opacity: 0.7;
        text-decoration: underline;
    }
    
    &+.link-button {
        margin-left: 5px;
    }
}

.flex-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-popup-icon {
    margin-right: 2px!important;
    margin-left: 0;
    min-width: 20px;

    &+.form-popup-icon {
        margin-left: 0!important;
    }

    &.dx-button.dx-state-hover {
        background-color: transparent;
    }

    .dx-button-content {
        padding: 5px 0;
    }
}
.page-toolbar .form-popup-icon.dx-button {
    box-shadow: none!important;

    .dx-icon-clear {
        color: rgba(117,117,117,.87);
        background-color: rgba(194,194,194,.87);
        border-radius: 12px;
        font-size: 12px;
    }
}

.image-popover {
    position: absolute;
}

.pimage-container {
    background-color: #ffffff;
    width: 100%;
    height: 100%;

    img {
        height: 100%;
        max-height: 492px;
        max-width: 488px;
    }
}
@media (max-width: 600px) {
    .pimage-container {
        img {
            max-height: 362px;
            max-width: 358px;
        }
    }
}

.grid-images {
    height: 50px;
    margin: -8px;

    img {
        max-width: 100%;
        max-height: 100%;
        margin-right: 2px;
    }
}

.large-images {
    padding-bottom: 20px;

    .grid-images {
        height: 100px;
    }
}

@media (max-width:600px), (max-height:600px) {
    .dx-drawer-content {
        overflow: auto!important;
        padding-bottom: 50px!important;
    }
    footer.footer {
        display: none;
    }
}
@import "../../themes/generated/variables.base.scss";

.app .page-caption {
    color: $base-accent;
    display: flex;

    h2 {
        margin-bottom: 12px;
    }

    .caption-vline {
        content: "";
        display: inline-block;
        padding: 2px;
        height: 1.2em;
        vertical-align: middle;
        margin-right: 10px;
        background-color: $base-accent;
    }

    .caption-help {
        flex-grow: 1;
        align-self: center;
        text-align: right;
        padding-right: 5px;
        font-size: 20px;
        color: $base-text-color;

        i {
            cursor: pointer;
            &+i {
                margin-left: 10px;
            }
        }
    }
}

@import "../../themes/generated/variables.base.scss";
@import "../../themes/var.custom.scss";

.header-bar {
    background-color: $base-bg;
    border-bottom: 1px solid $base-border-color;
    display: flex;
    min-height: 35px;

    .logo-container {
        height: 50px;
        padding: 5px 10px;

        img {
            height: 100%;
        }
    }

    .menu-toggle-container {
        width: 80px;
        align-items: center;
        justify-content: start;
        display: none;
    }

    .header-caption {
        flex: auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        h3 {
            margin: 0;
            font-weight: bold;
            cursor: pointer;
            font-size: 18px;

            i {
                margin-right: 4px;
            }
        }
    }

    .header-user {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 15px; 

        .dx-button {
            box-shadow: none !important;
        }
        
        .dx-button-content, .dx-button-content .dx-icon {
            font-size: 14px;
        }
    }
}

.screen-x-small {
    .header-bar {
        .logo-container {
            display: none;
        }

        .menu-toggle-container {
            display: flex;
        }

        .header-caption h3 {
            font-size: 14px;
        }
    }
}
@import '../../themes/var.custom.scss';

.wxconfig {
   
    h4 {
        margin: 10px 0;
    }
}

.wxconfig-image {
    display: inline-block;
    position: relative;

    img {
        width: 380px;
        margin: 5px;
    }
}

.wxconfig-image-close {
    position: absolute;
    top: 20px;
    right: 20px;

    i {
        font-size: 28px;
        color: $danger;
    }
}

.wxconfig-message-container {
    display: flex;

    > div {
        margin: 10px;
        flex: 1 1 auto;
    }
}
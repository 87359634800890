.announcement-sign {
    font-size: 28px;
    display: inline-block;
    width: 200px;   
}

.announcement-sign-photo {
    height: 50px;
    width: 100px;
}

.announcement-reply-time {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
}
.permission-form {
    .dx-field {
        margin-bottom: 0;
    }
    .dx-field-label {
        margin-left: 2rem;
        flex: 0 0 100px;
    }
    .dx-field-value {
        flex: 3 1 auto;
    }

    .object-label {
        display: inline-block;
        margin-right: 20px;
    }

    .link-button + .link-button {
        margin-left: 20px;
    }
}
@import "../../themes/generated/variables.base.scss";
@import "../../themes/var.custom.scss";

.custom-panel {
    border-radius: 0;
    display: flex;
    flex-direction: column;

    .custom-panel-title {
        flex: 0 0 auto;
        padding: 12px 16px 6px;
        font-size: 18px;
        background-color: $header-bg;
    }

    .custom-panel-body {
        flex: 1 1 auto;
        margin: 2px;
        padding: 5px;
    }

    .custom-panel-padding {
        padding: 15px;
    }
}
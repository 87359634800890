.toolbar-time-filter {
    display: flex;
    align-items: center;

    .dx-textbox {
        display: inline-block;
        width: 120px;
    }
    .dx-datebox {
        display: inline-block;
        margin-left: 5px;

        .dx-texteditor-input {
            padding-right: 4px!important;
        }
    }
}

.screen-x-small {
    .toolbar-time-filter {
        .dx-datebox {
            margin-bottom: 2px;
        }
    } 
}